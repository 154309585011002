.switch-button {
    background: rgba(207, 207, 207, 0.56);
    border-radius: 30px;
    overflow: hidden;
    width: 17vh;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    color: white;
    position: relative;
    padding-right: 120px;
    position: relative;
    text-align: center;

    &:before {
      content: "App Info";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 17vh;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      pointer-events: none;
    }
  
    &-checkbox {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
  
      &:checked + .switch-button-label:before {
        transform: translateX(120px);
        transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }
  
      & + .switch-button-label {
        position: relative;
        padding: 4px 0;
        display: block;
        user-select: none;
        pointer-events: none;
        text-align: center;
  
        &:before {
          content: "";
          background: rgb(208, 206, 206);
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 30px;
          transform: translateX(0);
          transition: transform 300ms;
        }
  
        .switch-button-label-span {
          position: relative;
          text-align: center;
        }
      }
    }
  }
  
.switch-container{
  background-color: rgba(207, 207, 207, 0.56);
  width: 550px;
  text-align: center;
  border-radius: 32px;
  padding: 12px 8px;
}

.switch-item{
  display: none;

  h1{
    padding: 10px 0px;
  }
}

.active{
  animation: 1s ease forwards FadeIn;
  display: block;
}

.body{
  padding-bottom: 20px;
}

.app-info-table{
  margin: 0 auto;
  border-collapse: collapse;
  tr:not(:first-child){
    border-top: 1px solid #c3c3c3;
  }

  td{
    padding: 3vw 3vw;
  }
}

.getItButton{
  width: 16vw;
}

.platformIcon{
  padding:12px;
  border-radius:50px;
  background-color:rgb(208, 206, 206);
  width:4vh;
  height:4vh;
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobileFlex{
  display:flex;
  justify-content:space-around;
  flex-direction: row;
}

.projectItem{
  margin:20px;
  padding:14px;
  width:300px;
  background-color:#e9e9e9;
  border-radius:16px;
}

.getItButtonContainer{
  display:flex;
  justify-content:space-around;
  vertical-align:middle;
  width:45vw;
  margin:2vh auto;
}

.ownerTitle{
  color: #3a17ff;
  text-decoration: none;
  display: block;
  transition: all 1s;
  text-align: center;
  padding-bottom: 3vh;

  :hover{
    color: #155FFF;
  }
}

.bottomLinks{
  a:not(:first-child){
    border-left: 1px solid #c3c3c3;
  }

  a{
    text-decoration: none;
    color: #155FFF;
    padding: 0px 10px;
  }
}