@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100&display=swap');

@font-face{
  font-family: Consolas;
  src:url('./fonts/CONSOLA.TTF');
}

*{
  margin: 0;
  top: 0;
  left: 0;
  font-family: Consolas;
}



.mainTitle{
  text-align:center;
  padding-top: 28vh;
  font-size: xxx-large;
  padding-bottom: 0vh;
}

.linkButton{
  text-align:right;
  padding:2px 14px;
  font-size:20px;
  border-radius:16px;
  font-size:16px;
  border:1px solid;
  text-decoration:none;
  color: #0044ff;
  transition: all cubic-bezier(0.215, 0.610, 0.355, 1) 0.4s;
}

.linkButton:hover{
  background-color: #0044ff;
  color: white;
}

.projectItemPlatform{
  display: flex;
  vertical-align: middle;
}

.projectItemPlatform *:not(:first-child){
  padding-left: 6px;
}

#loader{
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 1;
  transition: opacity 1s;
}

.loader-fadeout{
  opacity: 0;
}