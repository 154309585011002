@media only screen 
  and (max-device-width: 750px) { 
    .projectItem{
      margin: 20px auto !important;
    }

    .mobileFlex{
      flex-direction: column !important;
    }

    .switch-container{
      width: 90%;
    }

    .getItButtonContainer{
      width: 86%;
    }

    .getItButton{
      width: 34vw;
    }
}
