/* Try that : */

/* 
*{
  border-radius: 100% !important;
}
*/



.loader1, .loader2, .loader3, .loader4, .loader5, .loader6, .loader7, .loader8, .loader9, .loader10, .loader11, .loader12,
.loader13, .loader14, .loader15, .loader16, .loader17, .loader18, .loader19, .loader20, .loader21, .loader22, .loader23, .loader24{
	box-sizing:border-box;
	width:100px;
	height:100px;
	position:absolute;
}

.loader1{
	left:100px;
	top:100px;
}

.loader2{
	left:300px;
	top:100px;
}

.loader3{
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loader4{
	left:700px;
	top:100px;
}

.loader5{
	left:900px;
	top:100px;
}

.loader6{
	left:1100px;
	top:100px;
}

.loader7{
	left:100px;
	top:300px;
}

.loader8{
	left:300px;
	top:300px;
}

.loader9{
	left:500px;
	top:300px;
}

.loader10{
	left:700px;
	top:300px;
}

.loader11{
	left:900px;
	top:300px;
}

.loader12{
	left:1100px;
	top:300px;
}

.loader13{
	left:100px;
	top:500px;
}

.loader14{
	left:300px;
	top:500px;
}

.loader15{
	left:500px;
	top:500px;
}

.loader16{
	left:700px;
	top:500px;
}

.loader17{
	left:900px;
	top:500px;
}

.loader18{
	left:1100px;
	top:500px;
}

.loader19{
	left:100px;
	top:700px;
}

.loader20{
	left:300px;
	top:700px;
}

.loader21{
	left:500px;
	top:700px;
}

.loader22{
	left:700px;
	top:700px;
}

.loader23{
	left:900px;
	top:700px;
}

.loader24{
	left:1100px;
	top:700px;
}

.container-A{
	position:absolute;
	width:75%;
	height:75%;
	left:12.5%;
	top:12.5%;
}

.container-B{
	position:absolute;
	width:100%;
	height:100%;
	left:0;
	top:0;
}

.spinner1, .spinner2, .spinner3{
	position:absolute;
	width:100%;
	height:100%;
	left:0;
	top:0;
}

.spinner1{
	animation : rotation0 4s infinite linear, resize1 4s infinite linear;
}

.spinner2{
	animation : rotation0 4s infinite linear, resize2 4s infinite linear;
}

.spinner3{
	animation : rotation0 6s infinite linear, resize3 6s infinite linear;
}

.hex0, .hex120, .hex240{
	position:absolute;
	box-sizing:border-box;
	width:100%;
	height:57.74%;
	left:0;
	top:21.13%;
	border-left:2px solid black;
	border-right:2px solid black;
}

.loader8 .hex0, .loader8 .hex120, .loader8 .hex240, .loader11 .hex0, .loader11 .hex120, .loader11 .hex240,
.loader14 .hex0, .loader14 .hex120, .loader14 .hex240, .loader17 .hex0, .loader17 .hex120, .loader17 .hex240{
	border-right:none;
}

.loader9 .hex0, .loader12 .hex0, .loader15 .hex0, .loader18 .hex0 {
	border:none;
}

.hex0{
	animation:rotation0 4s infinite linear;
}

.hex120{
	transform:rotate(120deg);
	animation:rotation120 4s infinite linear;
}

.hex240{
	transform:rotate(240deg);
	animation:rotation240 4s infinite linear;
}

@keyframes rotation0{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}

@keyframes rotation120{
	0%{
		transform:rotate(120deg);
	}
	100%{
		transform:rotate(480deg);
	}
}

@keyframes rotation240{
	0%{
		transform:rotate(240deg);
	}
	100%{
		transform:rotate(600deg);
	}
}

@keyframes resize1{
	0%,50%,100%{
		width:100%;
		height:100%;
		left:0%;
		top:0%;
	}
	25%,75%{
		width:120%;
		height:120%;
		left:-10%;
		top:-10%;
	}
	12.5%,37.5%,62.5%,87.5%{
		width:70%;
		height:70%;
		left:15%;
		top:15%;
	}
}

@keyframes resize2{
	0%,33.33%,66.66%,100%{
		width:100%;
		height:100%;
		left:0%;
		top:0%;
	}
	16.66%,49.99%, 83.333%{
		width:70%;
		height:70%;
		left:15%;
		top:15%;
	}
}

@keyframes resize3{
	0%,16.66%,33.33%,50%,66.66%,83.33%,100%{
		width:100%;
		height:100%;
		top:0;
		left:0;
	}
	2.77%,19.44%,36.11%,52.77%,69.44%,86.11%{
		width:93.5%;
		height:93.5%;
		top:3.25%;
		left:3.25%;
	}
	5.55%,22.22%,38.88%,55.55%,72.22%,88.88%{
		width:84.4%;
		height:84.4%;
		top:7.8%;
		left:7.8%;
	}
	8.33%,25%,41.66%,58.33%,75%,91.66%{
		width:73.2%;
		height:73.2%;
		top:13.4%;
		left:13.4%;
	}
	11.11%,27.77%,44.44%,61.11%,77.77%,94.44%{
		width:84.4%;
		height:84.4%;
		top:7.8%;
		left:7.8%;
	}
	 13.88%,30.55%,47.22%,63.88%,80.55%,97.22%{
		width:93.5%;
		height:93.5%;
		top:3.25%;
		left:3.25%;
	}
}